// STYLES
import './styles/App.css'
// NAVIGATION
import {
  BrowserRouter,
  Route,
  Routes
} from 'react-router-dom'
import Admin from './pages/Admin'
import Home from './pages/Home'
import PrivacyPolicy from './pages/PrivacyPolicy'
import Support from './pages/Support'
import { useEffect } from 'react'

function App() {

  useEffect(() => {
    document.title = 'Switup'
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="admin" element={<Admin />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="support" element={<Support />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
